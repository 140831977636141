// .product-form-section{
//     .orders-tabs{
//         >.MuiBox-root{
//             padding: 0px 0px 24px 0px;
//         }
//     }
// }

//add-product-tab
.MuiGrid-root > .MuiGrid-item {
    padding-top: 0px !important; 
  }

.product-form-section{
    .orders-tabs{
        .MuiBox-root.css-19kzrtu{
            padding:8px  0px !important;
        }
    }
    #simple-tabpanel-3{
        .MuiTable-root {
            .MuiTableBody-root {
                .MuiTableRow-root {
                    .MuiTableCell-root {
                        &:nth-child(2) {
                            font-size: 14px;
                            font-weight: 500;
                            color: #10CDF2;
                        }
                        &:nth-child(4) {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.add-product-tab {
    .main-body {
        background-color: #F8F7F7;
        padding: 0;
        margin-bottom: 30px;
        margin-top:8px;

        .company-upper {
            padding: 30px;
            .text-field-wrap {
                display: flex;
                flex-direction: column;

                label {
                    font-family: poppins;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    // text-align: left;
                    display: block;
                    margin-bottom: 12px;
                    width: 100%;
                    color: #000;

                    &::after {
                        content: ' *';
                        color: #FF0000;
                    }

                    &.unastrik {
                        &:after {
                            content: '';
                        }
                    }
                }
                input {
                    background-color: #fff;
                }
                .MuiFormControl-root {
                    .MuiInputBase-root {
                        background-color: #fff;
                    }
                }
            }

            .block-title {
                font-size: 16px;
                color: #000;
                font-weight: 600;
                font-family: Poppins;
            }

            .upload-img {
                max-width: 200px;
                height: 200px;
                background-color: #fff;
                position: relative;
                padding: 5px;

                input {
                    visibility: hidden;
                    // z-index: 0;
                }

                .btn-1 {
                    max-width: 200px;
                    height: 200px;
                    border: 1px dashed #B1AEAE;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    bottom: 0px;
                    right: 0px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    cursor: pointer;
                }
            }

            h4 {
                font-size: 22px;
                color: #848080;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .status-upload-img {

                .status-upload-block {
                    display: flex;
                    align-items: flex-start;
                    gap: 15px;

                    .upload-img-item {
                        width: 60px;
                        height: 60px;
                    }

                    .progress-block {
                        width: 100%;

                        .progress-block-label {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .img-name {
                                font-size: 16px;
                                color: #848080;
                                font-weight: 600;
                            }

                            .img-size {
                                font-size: 16px;
                                color: #848080;
                                font-weight: 400;
                            }

                            .img-close {
                                font-size: 16px;
                                color: #848080;
                                font-weight: 400;
                                svg{
                                    cursor: pointer;
                                }
                            }
                        }

                        .linear-progress-bar {
                            margin: 15px 0px;
                        }

                        .process-block-label {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .process-done {
                                font-size: 16px;
                                color: #848080;
                                font-weight: 400;
                            }

                            .process-time {
                                font-size: 16px;
                                color: #848080;
                                font-weight: 400;
                            }
                        }
                    }
                }

                .select-items {
                    label {
                        color: #09801C;
                    }
                }
                hr{
                    margin-bottom: 10px;
                }
            }
        }
    }

    .btn-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .save-btn {
            background-color: #05A3C1;
            border-radius: unset;
            color: #fff;
            width: 132px;
            height: 45px;
        }

        .cancel-btn {
            background-color: #F2F6F9;
            border-radius: unset;
            color: #000;
            width: 132px;
            height: 45px;
        }

        .btn-text {
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
            text-transform: capitalize;
        }
    }
}

//Add product by Jebelz Id tab
.add-product-jebelzId {
    .main {
        background-color: #F8F7F7;
        padding: 0;
        margin-bottom: 30px;
        margin-top:8px;

        .company-upper {
            .text-field-wrap {
                display: flex;
                flex-direction: column;

                label {
                    font-family: poppins;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    // text-align: left;
                    display: block;
                    margin-bottom: 12px;
                    width: 100%;
                    color: #000;

                    &::after {
                        content: ' *';
                        color: #FF0000;
                    }

                    &.unastrik {
                        &:after {
                            content: '';
                        }
                    }
                    &.visibility-hidden-label{
                        visibility: hidden;
                    }
                }

                .load-btn {
                    background-color: #10CDF2;
                    color: #fff;
                    max-width: 266px;
                    min-height: 52px;
                    border-radius: unset;

                    .btn-text {
                        font-family: Poppins;
                        font-size: 22px;
                        font-weight: 500;
                        line-height: 33px;
                        text-transform: capitalize;
                    }
                }
            }
        }

        .product-images {
            padding: 0px 30px;

            label {
                font-family: poppins;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                // text-align: left;
                display: block;
                margin-bottom: 12px;
                width: 100%;
                color: #000;

                &::after {
                    content: ' *';
                    color: #FF0000;
                }

                &.unastrik {
                    &:after {
                        content: '';
                    }
                }
            }

            .img-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 33px;
                letter-spacing: 0em;
                color: #353535;
                margin: 10px 0;
                gap: 10px;
                .img-name {
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;

                    svg {
                        color: #05A3C1;
                    }
                }
            }
        }
    }

    .btn-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .save-btn {
            background-color: #05A3C1;
            border-radius: unset;
            color: #fff;
            width: 132px;
            height: 45px;
        }

        .cancel-btn {
            background-color: #F2F6F9;
            border-radius: unset;
            color: #000;
            width: 132px;
            height: 45px;
        }

        .btn-text {
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
            text-transform: capitalize;
        }
    }
}

//Bulk Upload tab
.bulk-upload {
    display: flex;
    flex-direction: column;
    gap: 100px;

    .main {
        .wrapper {
            .list-text {
                width: 50%;

                .text {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 38px;
                    letter-spacing: 0em;
                    color: #181818;
                }
            }

            .list-icon {
                
                width: 50%;

                .svg-icon {
                    cursor: pointer;
                    color: #05A3C1;
                    font-size: 40px;
                    margin: 0 40px;
                }

                .sheet-btn {
                    background-color: #05A3C1;
                    color: #fff;
                    border-radius: unset;
                    height: 45px;

                    h5 {
                        font-family: Poppins;
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-transform: capitalize;
                    }
                }
            }
        }

        .divider {
            border: 1px solid #F0EDED;
        }
    }

    .btn-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .save-btn {
            background-color: #05A3C1;
            border-radius: unset;
            color: #fff;
            width: 138px;
            height: 45px;
        }

        .cancel-btn {
            background-color: #F2F6F9;
            border-radius: unset;
            color: #000;
            width: 132px;
            height: 45px;
        }

        .btn-text {
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
            text-transform: capitalize;
        }
    }
}

//edit price Tab
.edit-price {
    .main {
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        gap: 8px;
        // margin-bottom: 20px;
        .MuiTable-root {
            .MuiTableBody-root {
                .MuiTableRow-root {
                    .MuiTableCell-root {
                        &:nth-child(6) {
                            MuiFormControl-root{
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }


    .btn-group {
        display: flex;
        flex-direction: row;
        // justify-content: flex-end;
        align-items: center;
        gap: 8px;

        #search_product {
            height: 45px !important;
            font-size: 16px;  
            border: 1px solid #ccc; 
            border-radius: 0px; 
            outline: none; 
            box-sizing: border-box; 
            
            &:focus {
              border-color: #007bff; 
            }
          }

        .save-btn {
            background-color: #05A3C1;
            border-radius: unset;
            color: #fff;
            width: 138px;
            height: 45px;
        }

        .cancel-btn {
            background-color: #F2F6F9;
            border-radius: unset;
            color: #000;
            width: 132px;
            height: 45px;
        }

        .btn-text {
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
            text-transform: capitalize;
        }
    }
}

//bulk-price tab
.bulk-price {
    display: flex;
    flex-direction: column;
    gap: 100px;

    .main {
        .wrapper {
            .list-text {
                width: 50%;

                .text {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 38px;
                    letter-spacing: 0em;
                    color: #181818;
                }
            }

            .list-icon {
                width: 50%;

                .svg-icon {
                    cursor: pointer;
                    color: #05A3C1;
                    font-size: 40px;
                    margin: 0 40px;
                }

                .sheet-btn {
                    background-color: #05A3C1;
                    color: #fff;
                    border-radius: unset;
                    height: 45px;

                    h5 {
                        font-family: Poppins;
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-transform: capitalize;
                    }
                }
            }
        }

        .divider {
            border: 1px solid #F0EDED;
        }
    }

    .btn-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .save-btn {
            background-color: #05A3C1;
            border-radius: unset;
            color: #fff;
            width: 138px;
            height: 45px;
        }

        .cancel-btn {
            background-color: #F2F6F9;
            border-radius: unset;
            color: #000;
            width: 132px;
            height: 45px;
        }

        .btn-text {
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
            text-transform: capitalize;
        }
    }
}

.tabs-panel-body {
    background-color: #fff;

    background-color: #F8F7F7;
    padding: 0px;

    .company-upper {
        .text-field-wrap {
            display: flex;
            flex-direction: column;

            label {
                font-family: poppins;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                // display: block;
                margin-bottom: 12px;
                width: 100%;
                color: #000;

                &::after {
                    content: ' *';
                    color: #FF0000;
                }

                &.unastrik {
                    &:after {
                        content: '';
                    }
                }
            }

            .load-btn {
                background-color: #10CDF2;
                color: #fff;
                width: 30%;
                border-radius: unset;

                .btn-text {
                    font-family: Poppins;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 33px;
                    text-transform: capitalize;
                }
            }
        }
    }

    .block-title {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        font-family: Poppins;
    }

    .upload-img {
        max-width: 200px;
        height: 200px;
        background-color: #fff;
        position: relative;
        padding: 5px;

        input {
            visibility: hidden;
            // z-index: 0;
        }

        .btn-1 {
            max-width: 200px;
            height: 200px;
            border: 1px dashed #B1AEAE;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
        }
    }

    h4 {
        font-size: 22px;
        color: #848080;
        font-weight: 600;
    }

    .status-upload-img {

        .status-upload-block {
            display: flex;
            align-items: flex-start;
            gap: 15px;

            .upload-img-item {
                width: 60px;
                height: 60px;
            }

            .progress-block {
                width: 100%;

                .progress-block-label {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .img-name {
                        font-size: 16px;
                        color: #848080;
                        font-weight: 600;
                    }

                    .img-size {
                        font-size: 16px;
                        color: #848080;
                        font-weight: 400;
                    }

                    .img-close {
                        font-size: 16px;
                        color: #848080;
                        font-weight: 400;
                    }
                }

                .linear-progress-bar {
                    margin: 15px 0px;
                }

                .process-block-label {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .process-done {
                        font-size: 16px;
                        color: #848080;
                        font-weight: 400;
                    }

                    .process-time {
                        font-size: 16px;
                        color: #848080;
                        font-weight: 400;
                    }
                }
            }
        }

        .select-items {
            margin-top: 15px;

            label {
                color: #09801C;
            }
        }
    }

    .product-images {
        label {
            font-family: poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            // text-align: left;
            display: block;
            margin-bottom: 12px;
            width: 100%;

            &::after {
                content: ' *';
                color: #FF0000;
            }

            &.unastrik {
                &:after {
                    content: '';
                }
            }
        }

        .img-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .img-name {
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    color: #05A3C1;
                }
            }
        }

    }

    .btn-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .save-btn {
            background-color: #05A3C1;
            border-radius: unset;
            color: #fff;
            width: 132px;
            height: 45px;
        }

        .cancel-btn {
            background-color: #F2F6F9;
            border-radius: unset;
            color: #000;
            width: 132px;
            height: 45px;
        }

        .btn-text {
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
            text-transform: capitalize;
        }
    }
}
.mobile__view__block{
    display: none ;
}
.product-search-box{
    position: relative;
}
.product-search-result{
    width: 100%;
    position: absolute;
    top: 92px;
    left: 0px;
    width: 100%;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
    z-index: 99;
    max-height: 330px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 10px;
        background-color: #ddd;
        border-radius: 20px;
      }
    //   &::-webkit-scrollbar-track {
    //     box-shadow: inset ;
    //   }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        outline: 1px solid #ccc;
        border-radius: 20px;
      }
      li{
        cursor: pointer;
      }
    .product-img{
        width: 60px;
        height: 50px;
        margin-right: 10px;
        img{
            width: 100%;
        }
    }
}

@media (min-width:320px) and (max-width:767px){
    .add-product-jebelzId .main .product-images{
        padding: 0px 15px;
    }
    .add-product-tab .main-body .company-upper{
        padding: 30px 15px;
    }
    .bulk-price .main .wrapper .list-icon{
        width: unset;
    }
    .bulk-upload .main .wrapper .list-icon{
        width: unset;
    }
    .bulk-upload .main .wrapper .list-text .text{
        font-size: 14px;
        line-height: 18px;
    }
    .bulk-price .main .wrapper .list-text .text{
        font-size: 14px;
        line-height: 18px;
    }
    .bulk-upload .main .wrapper .list-icon .svg-icon{
        margin: 0px 60px;
    }
    .bulk-price .main .wrapper .list-icon .svg-icon{
        margin: 0px 60px;
    }
    .mobile__view__none{
        display: none ;
    }
    .mobile__view__block{
        display: block ;
    }
    .add-product-jebelzId .main .product-images .img-wrapper {
        flex-direction: column;
    }
    .add-product-jebelzId .main .product-images .img-wrapper .img-name img {
        width: 100%;
    }
    .add-product-jebelzId .main .product-images .img-wrapper .img-name {
        width: 100%;
    }
}
@media (min-width:768px) and (max-width:991px){

}
@media (min-width:992px) and (max-width:1023px){

}